import React, { useEffect } from 'react'

const DIV_ID = 'kecy-player'

const SelectedVideo = ({ video }) => {
  useEffect(() => {
    document.getElementById(DIV_ID).innerHTML = ''
    window._bbx_embed.insert({
      url: video.embed,
      id: DIV_ID,
      autoplay: true
    })
  })

  return (
    <div id={DIV_ID}/>
  )
}

export default SelectedVideo

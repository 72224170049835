import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0 30px;
`

export const Title = styled.h1`
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  margin-bottom: 24px;
`

export const Text = styled.p`
  font-family: Lora,serif;
  font-kerning: none;
  font-size: 17px;
  line-height: 29px;
  margin-bottom: 24px;
`

export const Perex = styled(Text)`
  font-size: 19px;
  line-height: 32px;
  margin-top: 15px;
`

export const TextLink = styled.a`
  color: ${props => props.theme.button.background};
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.button.hover.background};
  }
`

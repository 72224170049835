import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin-top: 15px;
`

export const Title = styled.span`
  color: ${props => props.isSelected ? '#eb6600' : '#05171f'};
  font-size: 18px;
  line-height: 1.5;
  transition: 0.25s;
  font-weight: bold;
  text-decoration: ${props => props.isSelected ? 'underline' : 'none'};
  cursor: pointer;
  padding: 15px 5%;
  
  &:hover {
    color: ${props => props.theme.moreLink.colorHover};
    text-decoration: underline;
  }
`

export const Image = styled.img`
  width: 100%;
`

export const WrapperImage = styled.div`
  position: relative;
`

export const Video = styled.a`
  display: flex;
  flex-direction: column;
  max-width: 32%;
  flex: 1 1 32%;
    
    &:hover {
      text-decoration: none;
  }
`

export const Play = styled.span`
  position: absolute;
  left: 16px;
  bottom: 16px;
  border-radius: 2px;
  background: rgba(48,141,217,.9);
  padding: 0 10px 0 25px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.button.color};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  width: 90px;
  line-height: 32px;
  cursor: pointer;
  
  &:before {
    content: '';
    position: absolute;
    left: 10px;
    width: 0; 
    height: 0; 
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 8px solid ${props => props.theme.button.color};
  }
  
  &:hover {
    text-decoration: none;
  }
`

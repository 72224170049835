import React from 'react'
import { Wrapper, Video, WrapperImage, Image, Play, Title } from './StyledVideos'

const Videos = ({ selectedVideoId, videos, setVideo }) => {
  return (
    <Wrapper>
      {
        videos.map(({ title, image }, index) =>
          <Video key={`video-${index}`} onClick={() => setVideo(index)}>
            <WrapperImage>
              <Image src={image}/>
              <Play isSelected={index === selectedVideoId}>Přehrát</Play>
            </WrapperImage>
            <Title isSelected={index === selectedVideoId}>{title}</Title>
          </Video>
        )
      }
    </Wrapper>
  )
}

export default Videos

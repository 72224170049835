import React, { useState } from 'react'
import SelectedVideo from './SelectedVideo'
import Videos from './Videos'
import { Perex, Text, TextLink, Title, Wrapper } from './StyledKecyVideoPlayer'

const VIDEOS = [
  {
    guid: 16,
    image: 'https://cdn.xsd.cz/resize/b95bb7ade00530fb941355c5aa689c3a_resize=640,360_.jpg?hash=cfd4de03020601cceae458b527badb5f',
    embed: '//video.aktualne.cz/embed_iframe/kecy-a-politika-97-zemreme-s-inflaci-nebo-na-inflaci/r~bc5301b8b73f11edba5b0cc47ab5f122/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 97: Zemřeme s inflací nebo na inflaci?'
  },
  {
    guid: 15,
    image: 'https://cdn.xsd.cz/resize/85f8fdd8486030a4beff96010b464003_resize=640,360_.jpg?hash=a46e04df9cb02650312ec9420ec2321a',
    embed: '//video.aktualne.cz/embed_iframe/kecy-a-politika-96-stari-proti-mladym-budoucnost-ceske-polit/r~9e3c77cab74111edb1f50cc47ab5f122/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 96: Staří proti mladým. Budoucnost české politiky?'
  },
  {
    guid: 14,
    image: 'https://cdn.xsd.cz/resize/ad56eabca4523302b4d9257c9b49c975_resize=640,360_.jpg?hash=06ec5ac0c5ab534d6ed525790f29c142',
    embed: '//video.aktualne.cz/embed_iframe/kecy-a-politika-95-nejedly-jako-kolar-pavel-jako-zeman/r~e0456e02ac5011eda9eeac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 95: Nejedlý jako Kolář? Pavel jako Zeman?'
  },
  {
    guid: 13,
    image: 'https://cdn.xsd.cz/resize/013154e193fd317bbb4bd20eea84e7ab_resize=640,360_.jpg?hash=8e0133c96d475762fb91177c0e1a4e3f',
    embed: '//video.aktualne.cz/embed_iframe/kecy-a-politika-94-ministr-rakusan-zatcen-byrokraty/r~aebc7122a78811ed8b4e0cc47ab5f122/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 94: Ministr Rakušan zatčen byrokraty'
  },
  {
    guid: 12,
    image: 'https://cdn.xsd.cz/resize/85f8fdd8486030a4beff96010b464003_resize=640,360_.jpg?hash=a46e04df9cb02650312ec9420ec2321a',
    embed: '//video.aktualne.cz/embed_iframe/kecy-a-politika-93-general-venkova-i-mest/r~7cba9354a14611ed8980ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 93: Generál venkova i měst'
  },
  {
    guid: 11,
    image: 'https://cdn.xsd.cz/resize/1b426a4736313f01952dec3d6bfbe503_resize=640,360_.jpg?hash=08137bf80d2143f0e65272e4b057f994',
    embed: '//video.aktualne.cz/embed_iframe/kecy-a-politika-s-janou-cernochovou-vlastenectvi-valka-s-pol/r~637870e49cca11ed8b4e0cc47ab5f122/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika s Janou Černochovou: Vlastenectví, válka s Polskem a rozdělené Česko'
  },
  {
    guid: 10,
    image: 'https://cdn.xsd.cz/resize/ad56eabca4523302b4d9257c9b49c975_resize=640,360_.jpg?hash=06ec5ac0c5ab534d6ed525790f29c142',
    embed: '//video.aktualne.cz/embed_iframe/r~c10fed449bb811edba5b0cc47ab5f122/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 92: Babiš říká Polsku NE a stává se králem dezolátů'
  },
  {
    guid: 9,
    image: 'https://cdn.xsd.cz/resize/fedd8cdfbdd23ba787c1a4e8ec079b52_resize=640,360_.jpg?hash=23908983d482e03d67dc8b77821860df',
    embed: '//video.aktualne.cz/embed_iframe/r~df64dd32963011eda3c0ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 91: Arcibiskup Graubner rozhodne o prezidentovi'
  },
  {
    guid: 8,
    image: 'https://cdn.xsd.cz/resize/e8546041661035199ff94e52f1014141_resize=640,360_.jpg?hash=095252c31176c8e00a875338dc950374',
    embed: '//video.aktualne.cz/embed_iframe/r~5b8defc4906f11eda9eeac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Kecy a politika 90: Granát v kampani a mrtvé hlasy'
  },
  {
    guid: 7,
    image: 'https://cdn.xsd.cz/resize/05799b3afbe13e55bd4c948619abad35_resize=640,360_.jpg?hash=d3bd01fea698d8b428085480e6855932',
    embed: '//video.aktualne.cz/embed_iframe/r~3c10f1d48da211eda3c0ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Ruský speciál s Ivo Zelinkou a Michaelem Romancovem: 1. díl Putinova válka?'
  },
  {
    guid: 6,
    image: 'https://cdn.xsd.cz/resize/05799b3afbe13e55bd4c948619abad35_resize=640,360_.jpg?hash=d3bd01fea698d8b428085480e6855932',
    embed: '//video.aktualne.cz/embed_iframe/r~73efaee2905e11edba63ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Ruský speciál s Ivo Zelinkou a Michaelem Romancovem: 2. díl Patří Česko na Východ?'
  },
  {
    guid: 5,
    image: 'https://cdn.xsd.cz/resize/05799b3afbe13e55bd4c948619abad35_resize=640,360_.jpg?hash=d3bd01fea698d8b428085480e6855932',
    embed: '//video.aktualne.cz/embed_iframe/r~fc3319c4905e11eda873ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Ruský speciál s Ivo Zelinkou a Michaelem Romancovem: 3. díl Jaká je česká armáda?'
  },
  {
    guid: 4,
    image: 'https://cdn.xsd.cz/resize/db450bcb49ea3545963a5b6e4b846be3_resize=640,360_.jpg?hash=500d34fe81112fda68da22185f8c2249',
    embed: '//video.aktualne.cz/embed_iframe/r~c7da18f2905f11ed8980ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Čínský speciál s Tomášem Etzlerem a Martinem Hálou: 1. díl Čína nastupuje'
  },
  {
    guid: 3,
    image: 'https://cdn.xsd.cz/resize/db450bcb49ea3545963a5b6e4b846be3_resize=640,360_.jpg?hash=500d34fe81112fda68da22185f8c2249',
    embed: '//video.aktualne.cz/embed_iframe/r~6c1de402906011eda3c0ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Čínský speciál s Tomášem Etzlerem a Martinem Hálou: 2. díl Čína vs. covid'
  },
  {
    guid: 2,
    image: 'https://cdn.xsd.cz/resize/db450bcb49ea3545963a5b6e4b846be3_resize=640,360_.jpg?hash=500d34fe81112fda68da22185f8c2249',
    embed: '//video.aktualne.cz/embed_iframe/r~b3c3f24c906011eda3c0ac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Čínský speciál s Tomášem Etzlerem a Martinem Hálou: 3. díl Čína vs. Ukrajina'
  },
  {
    guid: 1,
    image: 'https://cdn.xsd.cz/resize/db450bcb49ea3545963a5b6e4b846be3_resize=640,360_.jpg?hash=500d34fe81112fda68da22185f8c2249',
    embed: '//video.aktualne.cz/embed_iframe/r~fcd88e66906011eda9eeac1f6b220ee8/?area=ostatni&device=d&discovery=atv-vid-1&site=centrum',
    title: 'Čínský speciál s Tomášem Etzlerem a Martinem Hálou: 4. díl Čína a Česko'
  }
]

const KecyVideoPlayer = () => {
  const [selectedVideoId, setSelectedVideoId] = useState(0)

  const selectVideo = id => {
    setSelectedVideoId(id)
    window.scrollTo(0, 0)
  }

  return (
    <Wrapper>
      <Title>Kecy a politika: Nekorektní podcast o aktuálním dění v politice</Title>
      <Perex>
        Video podcast komentátora Bohumila Pečinky a Petrose Michopulose. Současná politika pohledem dvou rozdílných osobností, kteří se nebojí říct své názory.
      </Perex>
      <SelectedVideo video={VIDEOS[selectedVideoId]}/>
      <Videos selectedVideoId={selectedVideoId} videos={VIDEOS} setVideo={selectVideo}/>
      <Text>
        Premiérový díl video podcastu můžete sledovat zdarma každé úterý ráno na Centrum.cz. <TextLink href='https://www.pickey.cz/kecyapolitika'>Předplatné</TextLink> vám poskytne plnou verzi epizod (délka epizody je 90+ min), zároveň získáte kompletní archiv, možnost klást autorům dotazy a zasoutěžit si na účasti na akci s fanoušky Kecy a politika zvané backstage.
      </Text>
    </Wrapper>
  )
}

export default KecyVideoPlayer
